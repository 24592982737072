.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 300px; */
    height: 68px;
    margin: 65px auto;
    border: 1px solid #101010;
    padding: 0 10px;
    border-radius: 4px;
}
  
  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer
  }
  
  button:focus {
    outline: none;
  }
  
  button img {
    width: 25px;
  }

  .ref-section{
    background-color: transparent;
    margin: 20px;
    text-align: center;
  }

  .staking-overflow .tab-content2 {
    padding: 56px 30px;
    box-shadow: 0 4px 25px rgb(51 51 51 / 15%);
    background: aliceblue;
}

.level-span{
    color: #0a202c;
}